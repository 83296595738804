import React from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'

import PriceBox from '@atoms/PriceBox'
import TextBox from '@atoms/TextBox'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import PricingTabs from '@organisms/PricingTabs'
import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

import { pricing } from '@content/pricing'

const PricingPage = ({ data, transitionStatus }) => {
  const pageData = data.pageData

  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="Pricing" center>
          <h2>Begin Free, Boost Later!</h2>
          <p>
              We recommend to start with Free plan <br/>and upgrade it as your business grows
          </p>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        <PricingTabs>
          {pricing.map(plan => (
            <PriceBox
              best={plan.best}
              name={plan.name}
              price={plan.price}
              priceYear={plan.priceYear}
              benefits={plan.benefits}
            />
          ))}
        </PricingTabs>
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query PricingPageQuery {
    pageData: datoCmsPricingPage {
      seoMetaTags {
        tags
      }
    }
  }
`

export default PricingPage
