import React from 'react'
import PropTypes from 'prop-types'

import AbsoluteSubtext from '@atoms/AbsoluteSubtext'
import Button from '@atoms/Button'
import Benefits from '@molecules/Benefits'

import { Wrapper, Top, Price, Footer } from './styles'
import SignUpButton from '../../molecules/SignUpButton'

const PriceBox = ({ best, name, price, priceYear, benefits, children }) => {
  return (
    <Wrapper best={best}>
      <Top>
        {name && <h3>{name}</h3>}
        {price && <Price after="/month">{price}</Price>}
        {priceYear && (
          <Price annualy after="/year">
            {priceYear}
          </Price>
        )}
        {children}
        <Benefits header="" data={benefits} />
      </Top>
      <Footer>
        <SignUpButton
          noCreditCard
          text="Try for free"
          buttonColor={best ? 'primary' : 'light'}
        />
      </Footer>
    </Wrapper>
  )
}

PriceBox.propTypes = {
  title: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

PriceBox.defaultProps = {
  title: null,
  center: false,
  children: null,
}

export default PriceBox
