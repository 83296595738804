import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { Wrapper, List } from './styles.js'
import CheckmarkSVG from '@images/checkmark-circle.svg'

const Benefits = ({ header, data }) => (
  <Wrapper>
    <h3>{header}</h3>
    <List>
      {data.map(benefit => (
        <li key={benefit.id}>
          <SVG src={CheckmarkSVG} />
          {benefit.title}
        </li>
      ))}
    </List>
  </Wrapper>
)

Benefits.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

Benefits.defaultProps = {
  data: [],
}

export default Benefits
