import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0;
  width: 100%;

  h3 {
    margin: 0 0 1rem;
    font-size: 24px;
    text-align: center;
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 1rem;

    svg {
      margin-right: 10px;

      path {
        fill: var(--brand-500);
      }
    }
  }
`
