export const pricing = [
  {
    id: '001',
    best: false,
    name: 'Free',
    description: '@DESC@',
    price: '0$',
    priceYear: '0$',
    benefits: [
      {
        id: '001',
        title: '1 board',
      },
      {
        id: '002',
        title: '10 ideas',
      },
      // {
      //   id: '003',
      //   title: '1 admin user',
      // },
      {
        id: '004',
        title: 'Roadmap',
      },
      {
        id: '005',
        title: 'Releases and versioning',
      },

      { id: '006', title: 'Customizable themes' },
    ],
  },
  {
    id: '002',
    best: true,
    name: 'Startup',
    description: '@DESC@',
    price: '15$',
    priceYear: '150$',
    benefits: [
      {
        id: '001',
        title: '2 boards',
      },
      {
        id: '002',
        title: '250 ideas',
      },
      // {
      //   id: '003',
      //   title: '3 admin users',
      // },
      {
        id: '004',
        title: 'Roadmap',
      },
      {
        id: '005',
        title: 'Releases and versioning',
      },

      { id: '006', title: 'Customizable themes' },
    ],
  },
  {
    id: '003',
    best: true,
    name: 'Company',
    description: '@DESC@',
    price: '35$',
    priceYear: '350$',
    benefits: [
      {
        id: '001',
        title: '10 boards',
      },
      {
        id: '002',
        title: 'Unlimited ideas',
      },
      // {
      //   id: '003',
      //   title: '10 admin users',
      // },
      {
        id: '004',
        title: 'Roadmap',
      },
      {
        id: '005',
        title: 'Releases and versioning',
      },

      { id: '006', title: 'Customizable themes' },
      { id: '007', title: 'Unlimited widgets to add for your websites' },
      { id: '008', title: 'Email support' },
    ],
  },
  {
    id: '004',
    best: false,
    name: 'Enterprise',
    description: '@DESC@',
    price: '90$',
    priceYear: '900$',
    benefits: [
      {
        id: '001',
        title: 'Unlimited boards',
      },
      {
        id: '002',
        title: 'Unlimited ideas',
      },
      // {
      //   id: '003',
      //   title: 'Unlimited admin users',
      // },
      {
        id: '004',
        title: 'Roadmap',
      },
      {
        id: '005',
        title: 'Releases and versioning',
      },

      { id: '006', title: 'Customizable themes' },
      { id: '007', title: 'Unlimited widgets to add for your websites' },
    ],
  },
]
