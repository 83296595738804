import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 3rem;
  display: grid;

  ${({ theme }) => theme.media.l} {
    grid-template-columns: repeat(4, 1fr);
  }
`
